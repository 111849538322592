<template>
	<div class="searchBox">
		<div class="topbox">
			<van-search
			  v-model="searchKey"
			  show-action
			  autofocus
			  shape="round"
			  :placeholder="$t('common.searchkey')"
			  @search="onSearch"
			>
				<template #action>
					<div @click="onSearch">{{ $t('common.confirm') }}</div>
				</template>
			</van-search>
		</div>
		<template v-if="gameList.length > 0">
			<div class="resultList" v-if="isMobile">
				<div class="resultItem" v-for="(item,index) in gameList" :key="index">
					<div class="iconBox cimg">
						<img :src="item.gameIcon">
					</div>
					<div class="detailBox">
						<p class="gameName">{{item.gameKrName}}</p>
						<p class="labels">
							<span v-if="item.gameType">{{item.gameType}}</span>
							<span v-if="item.gameKrName">{{item.gameKrName}}</span>
						</p>
						<p class="tags">
							<span v-for="(item,index) in formatTags(item.tags)" :key="index">{{item}}</span>
						</p>
					</div>
					<div class="btnBox" @click="handleDetail(item)">
						{{ $t('common.detail') }}
					</div>
				</div>
			</div>
			<div class="gamelist" v-else>
				<div class="item" v-for="(item,index) in gameList" :key="index">
					  <div class="iconbox">
						  <span class="tag">{{index+1}}</span>
						  <div class="icon">
							  <img :src="item.gameIcon" />
							  <a @click="handleDetail(item)" class="btn">{{ $t('common.detail') }}</a>
						  </div>
					  </div>
					  <div class="descbox">
						  <h4>{{item.gameKrName}}</h4>
						  <p>{{item.gameSynopsis}}</p>
						  <span v-if="item.gameType">{{item.gameType}}</span>
					  </div>
					  <div class="imgsbox">
						  <ul>
							  <template v-for="(item,index) in formatArr(item.gameFiveCharts)" :key="index">
								  <li v-if="index < 3">
									  <img :src="item">
								  </li>
							  </template>
						  </ul>
					  </div>
				  </div>
			</div>
		</template>
		<template v-else>
			<div class="emptyBox" v-if="loading">
				<van-loading color="#51bac0" />
			</div>
			<div class="emptyBox" v-else>
				{{ errorMsg }}
			</div>
		</template>
	</div>
</template>

<script setup>
	import { getCurrentInstance,onMounted,ref,computed } from 'vue'
	import { useRoute,useRouter } from 'vue-router';
	import useUserStore from '@/store/modules/user'
	import useConfigStore from '@/store/modules/config'
	import { getSearch } from "@/api/index";
	
	const { proxy } = getCurrentInstance();
	const route = useRoute();
	const router = useRouter();
	const osType = ref(useUserStore().ostype);//游戏横竖屏
	const key = computed(() => {
		return route.path
	})
	const gameList = ref([])
	const searchKey = ref('')
	const errorMsg = ref('')
	const loading = ref(true)
	const isMobile = computed(() => {
		return useConfigStore().isMobile
	})
	const params = ref({})
	onMounted(() => {
		const query = router.currentRoute.value.params
		params.value = Object.assign({},query)
		useConfigStore().setSearch(0)
		onSearch()
	})
	const isChinese = (text) => {
	    return /[\u4e00-\u9fff]/.test(text);
	}
	const isKorean = (text) => {
	    return /[\x3130-\x318F\xAC00-\xD7AF]/.test(text);
	}
	const onSearch = () => {
		let args = {}
		if(searchKey.value){
			if(isChinese(searchKey.value)){
				args.gameOldName = searchKey.value
			}else{
				args.gameName = searchKey.value
			}
		}
		loading.value = true
		gameList.value = []
		errorMsg.value = ''
		getSearch(args).then((res) => {
			console.log(res)
			loading.value = false
			gameList.value = res.data || []
			if(gameList.value.length == 0){
				errorMsg.value = res.msg
			}
		}).catch((e) => {
			loading.value = false
			errorMsg.value = e.msg
		})
		console.log('搜索')
	}
	const onCancel = () => {
		router.push({
		  name:'Recommend',
		  params: params.value
		});
	}
	const formatTags = (str) => {
		if(!str){
			return []
		}
		if(str.indexOf(',') > -1){
			return str.split(',')
		}else{
			return [str]
		}
		
	}
	const handleDetail = (row) => {
		router.push({
		  path:'/game/'+row.gameId
		});
	}
	const formatArr = (str) => {
		let arr = [];
		if(str){
			if(str.indexOf(',') > -1){
				arr = str.split(',');
			}else{
				arr = [str]
			}
		}
		return arr;
	}
</script>

<style lang="less" scoped>
.pcbox{
	.searchBox{
		background: none;margin-top: 0.5rem;
		.topbox{
			background: url('~@/assets/images/common/top_bg.jpg') no-repeat center;
			background-size: 100%;padding-bottom: 0.5rem;margin-bottom: 0.5rem;
		}
	}
	.gamelist{
		width: 100%;
		.item{
			width: 100%;display: flex;height: 260px;overflow: hidden;margin-bottom: 20px;background: #fff;box-shadow: 0 0 20px #eee;
			.iconbox{
				width: 220px;position: relative;
				.tag{
					position: absolute;left: 0;top: 0;display: block;color: #fff;z-index: 111;padding: 5px 0 0 8px;
					&::before{
						width: 0;height: 0;border-top: 50px solid #999;border-right: 50px solid transparent;content: '';display: block;left: 0;top: 0;position: absolute;z-index: -1;
					}
				}
				.icon{
					display: flex;align-items: center;width: 150px;margin: 0 auto;flex-wrap: wrap;justify-content: center;height: 100%;padding: 20px 0;box-sizing: border-box;
					img{width: 100%;border-radius: 25px;}
					.btn{display: block;padding: 8px 20px;background: #ff5a00;color: #fff;}
				}
			}
			.descbox{
				flex: 1;background: #fafafa;height: 100%;padding: 22px 18px;box-sizing: border-box;
				h4{font-size: 24px;line-height: 30px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;margin-bottom: 15px;}
				span{display: inline-block;padding: 2px 8px;margin-top: 20px;font-size: 12px;border: 1px solid #ff5a00;color: #ff5a00;}
				p{
					line-height: 22px;font-size: 14px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					overflow: hidden;
					-webkit-line-clamp: 5;
				}
			}
			.imgsbox{
				width: 420px;
				ul{
					display: flex;align-items: center;height: 100%;justify-content: space-between;
					li{
						width: 32%;height: 100%;text-align: center;overflow: hidden;
						img{height: 100%;transform: translate(-50%,0);margin-left: 50%;}
					}
				}
			}
			&:first-child{
				.iconbox{
					.tag::before{
						border-top: 50px solid #fa541c;
					}
				}
			}
			&:nth-child(2){
				.iconbox{
					.tag::before{
						border-top: 50px solid #ff7a45;
					}
				}
			}
			&:nth-child(3){
				.iconbox{
					.tag::before{
						border-top: 50px solid #ff9c6e;
					}
				}
			}
		}
	}
}
.searchBox{
	background: url('~@/assets/images/common/home_up_bg.png') no-repeat top;
	background-size: 100%;height: 100%;
	/deep/.van-search__action:active{
		background-color: transparent;
		color: #fff;
	}
	.topbox{
		padding-top: 0.5rem;
		/deep/input::-webkit-input-placeholder{ color: #ABABAB; }
		/deep/input::-moz-placeholder{ color: #ABABAB; }
		/deep/input::-ms-input-placeholder{ color: #ABABAB; }
		/deep/input::placeholder{ color: #ABABAB; }
	}
	.searchBtn{
		display: flex;align-items: center;
	}
	.resultList{
		padding: 0.3125rem 0.75rem;box-sizing: border-box;width: 100%;
		height: calc(100% - 3.375rem);overflow-y: scroll;
		.resultItem{
			display: flex;align-items: center;margin-bottom: 0.625rem;
			.iconBox{
				flex-shrink: 0;width: 3.5rem;height: 3.5rem;border-radius: 0.75rem;
				margin-right: 0.5rem;
			}
			.detailBox{
				flex: 1;
				.gameName{
					font-size: 0.9375rem;font-weight: 500;color: #303133;
				}
				.labels{
					font-size: 0.8125rem;color: #6b6b6b;margin-bottom: 0.1875rem;
					display: inline-flex;align-items: center;width: 100%;
					span{
						display: inline-block;height: 0.75rem;line-height: 0.75rem;
					}
					span + span{
						padding-left: 0.3125rem;
						border-left: 0.0625rem solid #6b6b6b;margin-left: 0.3125rem;
					}
				}
				.tags{
					display: inline-flex;align-items: center;justify-content: flex-start;
					span{
						display: inline-block;border: 1px solid #f42ab4;color: #f42ab4;
						font-size: 0.75rem;padding: 0 0.3125rem;border-radius: 0.3125rem;
						&:nth-child(even){
							border: 1px solid #FB9823;color: #FB9823;
						}
					}
					span + span{
						margin-left: 0.3125rem;
					}
				}
			}
			.btnBox{
				flex-shrink: 0;margin-left: 0.5rem;font-size: 0.8125rem;
				background: linear-gradient(to right,#51bac0,#83d5d9);color: #fff;
				padding: 0.25rem 0.625rem;border-radius: 0.3125rem;
			}
		}
	}
}
</style>